import Layout from "components/layouts/Layout"
import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import UniversalImage from "components/common/ui/UniversalImage"
import Section from "components/common/ui/Section"
import * as styles from "./unsubscribe.module.scss"
import SEO from "components/common/Seo"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"
import { translate } from "src/utils/language"

const UnsubscribePage = ({ data }) => {
  const locale = useCurrentLanguage()
  const miscSettingsPage = translate(data.miscSettingsPage, locale)

  const {
    label: title,
    img: image,
    sorry,
  } = miscSettingsPage.otherSettings.unsubscribe

  return (
    <Layout>
      <SEO title={title} description={sorry} />
      <Section>
        <Container className={styles.container}>
          <UniversalImage image={image} className={styles.image} />
          <h1 className={styles.title}>{title}</h1>
          <p>{sorry}</p>
        </Container>
      </Section>
    </Layout>
  )
}

export default UnsubscribePage

export const query = graphql`
  query {
    miscSettingsPage: wpSettingsPage(slug: { eq: "misc" }) {
      ...UnsubscribeFragment
      translations {
        ...UnsubscribeFragment
      }
    }
  }
`
